// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/banner_echeqs.jpg";
import SegmentImage from "../../imgs/foto 2.jpg";
import slick_items from "../../misc/echeqs_related/slick_items_echeqs.json";
import {SalWrapper} from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Link } from "gatsby";

const pageTitle = 'Potenciá tu empresa en el Mercado de Capitales con Echeqs | Balanz';
const pageDesc = 'Conocé todo sobre Echeqs y aumentá la productividad de tu empresa con los Instrumentos Financieros que te brinda el Mercado. Abrí cuenta en Balanz.';

let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Echeqs.</span>`;

const EcheqsPage = () => {
  //const [mobile, setMobile] = useState(false);
    const [xs, setXs] = useState(false);
    const [faq, setFaq] = useState({
        list: []
    });
  useEffect(() => {
      const handleXs = () => {
          const isXs = window.innerWidth <= 768;
          setXs(isXs);
      };

      window.addEventListener('resize', handleXs);

      handleXs();

      return () => {
          window.removeEventListener("resize", handleXs);
      };
  }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };

    useEffect(() => {
        apiRequest
            .get("/v1/faq/14")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])

  const page = (
    <>
        <ProductBanner
          bannerContainerClassName="bannerOpciones"
          bannerTrasparecy="bannerMask"
          productTitle="Echeqs"
          bannerImage={Banner}
          bannerImageClassName="ocionesBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
    <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué es un Cheque Electrónico?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                Un cheque electrónico, también llamado Echeq, es un instrumento que consiste en un cheque emitido desde tu Home Banking que se utiliza como medio cancelatorio de deudas y permite hasta 100 endosos. Los cheques electrónicos pueden ser emitidos tanto por personas físicas como jurídicas.<br /><br />
                                Su objetivo es simplificar la operatoria de emisión, endoso, negociación y circulación, haciéndola más efectiva y segura. Reduce costos operativos, tiempos de operación y motivos de rechazo por defectos formales en relación con el cheque tradicional. Este instrumento cumple la misma función que un cheque tradicional, lo que cambia es su formato de papel a digital.
                            </p>
                        </Col>
                    </Row>

                </Container>
            </div>
        </section>
        <section className="fullwidth operaciones">
            <Container>
                <Row>
                    <h2 className="mb-2">Negociación de Cheques Electrónicos en el mercado</h2>
                </Row>
                <Row className="pt-3">
                    <Col xs={12}>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >
                            La negociación de cheques (ya sean tradicionales o Echeqs) en el MAV (Mercado Argentino de Valores) permite, en general, obtener tasas de descuento más bajas que las tasas que podrían obtenerse en caso de descontar el cheque en el sistema bancario ya que se accede a una mayor oferta tanto de tasas como de plazos y a un sistema más ágil de negociación. Esto brinda una alternativa conveniente de financiamiento para las pequeñas y medianas empresas, ya que estas tienen que afrontar habitualmente mayores costos crediticios respecto a las demás empresas porque son percibidas como más riesgosas.
                        </p>

                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >
                            Para poder endosar un Echeq en el mercado debemos consignar en el documento la siguiente leyenda: <i>Para su negociación en Mercados bajo competencia de CNV.</i>
                        </p>

                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >
                            Los cheques, librados tanto de forma física como los Echeq, se pueden negociar en distintos segmentos del mercado de valores, los cuales son: el segmento avalado, el segmento directo garantizado por el propio mercado, el segmento directo no garantizado y el segmento patrocinado. Cuantas más garantías brinde el emisor, menor riesgo percibirá el inversor y ello permitirá al vendedor de los cheques afrontar un menor costo financiero para acceder al financiamiento que necesita.
                        </p>

                        <div
                            data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="200">
                            <Image className="mb-2" src={SegmentImage} style={{ width: '100%' }}></Image>
                        </div>


                        <ul
                            data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="200">

                            <li>
                                <p className="base-text">
                                    <strong>Segmento Avalado:</strong> este segmento es exclusivo para el financiamiento de pequeñas y medianas empresas. En el mismo se negocian cheques de pago diferido que son avalados por las Sociedades de Garantía Recíproca (SGR) a sus socios partícipes (que son las PYME). Esta modalidad de negociación permite a este tipo de empresas acceder a financiamiento de corto plazo afrontando un costo financiero muy competitivo.
                                </p>
                            </li>

                            <li>
                                <p className="base-text">
                                    <strong>Segmento Directo Garantizado por el Mercado:</strong> en este segmento se venden cheques que son garantizados por el propio mercado en el que se negocia, el cual a su vez exige la presentación de contragarantías. Se pueden negociar cheques propios o de terceros.
                                </p>
                            </li>

                            <li>
                                <p className="base-text">
                                    <strong>Segmento Directo No Garantizado:</strong> en este tipo de segmento de negociación de cheques de pago diferido no hay una entidad o activos que garanticen el cobro del cheque al inversor que los compra. La garantía de cobro depende de la solvencia del librador y su historial crediticio, por lo tanto, tiene mayor riesgo y una tasa de financiamiento más alta que los otros tipos de Echeqs o cheques.
                                </p>
                            </li>

                            <li>
                                <p className="base-text">
                                    <strong>Segmento de Cheques Patrocinados:</strong> se trata de cheques donde la empresa libradora es en general mediana o grande y solicita autorización para que los cheques de pago diferido que entrega en calidad de pago a sus proveedores, estos los puedan negociar en el mercado y de este modo anticipar el cobro.
                                </p>
                            </li>

                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidthbg mira-eventos part-blue py-2 echeqs-mercado-capitales"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}  className="column col-text text-center">
                            <div className="section-content mt-0">
                                <h3 className="titulo mx-auto"
                                    data-sal="slide-up"
                                    data-sal-delay="100"
                                    data-sal-duration="200"
                                >¿Querés <b>potenciar tu empresa</b> en el mercado de capitales?</h3>
                                <p className="texto mx-auto"
                                   data-sal="slide-up"
                                   data-sal-delay="100"
                                   data-sal-duration="200"
                                >Aumentá tu productividad y lográ mayor financiamiento a través del descuento de cheques electrónicos.</p>
                                <div className="cta py-3">
                                    <Link href="/empresas">
                                    <Button variant="nofill-2" text="Contactanos" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 Razones para financiarte con un Cheque Electrónico Diferido en el Mercado de Capitales</h2>
                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        {/*<section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Opciones?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/atsav48P8_o"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 <Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>
             </Container>
        </section>*/}

        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'opciones-faq'} />
                </Row>
              </Container>
        </section>
    </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"opciones"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default EcheqsPage;
